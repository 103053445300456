<template>
  <div>
    <section>
      <AdvanceTable ref="advanceTable" url="/crm/pipeline" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @clear="clearData" @edit="openModalForEdit">
        <template #title>
          {{ $t("Pipeline") }}
        </template>
        <template #button>
          <b-button variant="info" class="mr-1" @click="openModalForCreate">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{ $t("Pipeline") }}</span>
          </b-button>
        </template>

        <template #head(team_head)="data">
          <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
            {{ data.label }}
            <template v-if="data.field.sortable">
              <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
              <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
            </template>
          </div>
          <b-form-select :options="userList" v-model="form.team_head" value-field="id" text-field="name" @change="search('team_head', form.team_head)">
            <template #first>
              <b-form-select-option :value="''" ></b-form-select-option>
            </template>
          </b-form-select>
        </template>

        <template #head(team_lead)="data">
          <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
            {{ data.label }}
            <template v-if="data.field.sortable">
              <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
              <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
            </template>
          </div>
          <b-form-select :options="userList" v-model="form.team_lead" value-field="id" text-field="name" @change="search('team_lead', form.team_lead)">
            <template #first>
              <b-form-select-option :value="''" ></b-form-select-option>
            </template>
          </b-form-select>
        </template>

        <template #cell(team_head)="row">
            <span v-for="(item,index) in userList" :key="index">
                <div v-for="member in row.item.members">
                  <span v-if="item.id === member.user.id && member.role === 'TEAM_HEAD'">{{ item.name }}</span>
                  <span v-else></span>
                </div>
            </span>
        </template>

        <template #cell(team_lead)="row">
            <span v-for="(item,index) in userList" :key="index">
                <div v-for="member in row.item.members">
                  <span v-if="item.id === member.user.id && member.role === 'TEAM_LEAD'">{{ item.name }}</span>
                  <span v-else></span>
                </div>
            </span>
        </template>
      </AdvanceTable>
    </section>

    <b-modal id="modal-create-pipeline" hide-footer size="xl" v-model="isShow">
      <el-form>
      <div class="card p-2" v-loading="isLoading">
        <el-descriptions direction="vertical" class="margin-top" :title="$t(pipelineTitle)" border :column="2">
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-guide"></i>
              {{$t('Pipeline Name')}}
            </template>
            <el-input required v-model="form.name" :validate-event="true"
                      placeholder="Input New pipeline Name"></el-input>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-guide"></i>
              {{$t('Sorting Order')}}
            </template>
            <b-form-input v-model="form.order_nr" value-field="id" text-field="order_nr"/>
          </el-descriptions-item>

          <el-descriptions-item span="2">
            <template slot="label">
              <i class="el-icon-guide"></i>
              {{$t('Members')}}
            </template>
            <table style="width: 100%">
              <thead>
              <tr>
                <th>{{$t('Role')}}</th>
                <th>{{$t('User')}}</th>
                <th>{{$t('Manager')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(member, rowId) in form.members">
                <td>
                  <b-form-select :options="roleList" v-model="member.role" value-field="id" text-field="name"></b-form-select>
                </td>
                <td>
                  <b-form-select :options="userList" v-model="member.user.id" value-field="id" text-field="name"></b-form-select>
                </td>
                <td>
                  <b-form-select :options="userList" v-model="member.manager.id" value-field="id" text-field="name" v-if="member.role !== 'TEAM_HEAD' && member.role !== 'TEAM_LEAD'"></b-form-select>
                </td>
                <td style="width: 100px; text-align: center;"><a href="#" @click="removeMember(rowId)"><i class="el-icon-remove"></i> {{$t('Remove')}}</a></td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="width: 100px; text-align: center;"><a href="#" @click="addMember()"><i class="el-icon-user"></i> {{$t('Add')}}</a></td>
                </tr>
              </tfoot>
            </table>
          </el-descriptions-item>

          <el-descriptions-item span="2">
            <template slot="label">
              <i class="el-icon-guide"></i>
              {{$t('Stages')}}
            </template>
            <b-row>
              <b-col md="12" style="display: flex; overflow-x: scroll">
                <draggable v-model="form.stageList" v-bind="dragOptions" @start="drag = true" @end="drag = false" draggable=".draggable-stage">
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null" style="display: flex">
                    <b-card class="mb-30 cursor-pointer" v-for="(stage, k) in form.stageList" :key="k" style="flex: 1 1 0%; padding: 0" :class="!stage.is_protected ? 'draggable-stage' : ''">
                      <div>
                        <div>
                          <span>{{$t('Stage Name')}}</span>
                          <el-input class="text-muted mb-1" v-model="stage.name" :disabled="stage.is_protected"></el-input>
                        </div>
                        <div>
                          <span>{{$t('Probability')}}</span>
                          <el-input class="text-muted mb-1" v-model="stage.deal_probability" :disabled="stage.is_protected"></el-input>
                        </div>
                        <div>
                          <span>{{$t('Valid Organization Statuses')}}</span>
                          <br/>
                          <el-select class="text-muted mb-1" v-model="stage.valid_statuses" :disabled="stage.is_protected" multiple="multiple" style="width: 100%">
                            <el-option v-for="item in approvalStatusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </div>
                        <div v-if="!stage.id && !stage.is_protected">
                          <feather-icon icon="Trash2Icon" size="24" class="text-muted" @click="deleteStage(k, stage)" style="cursor: pointer" />
                        </div>
                      </div>
                    </b-card>
                  </transition-group>
                </draggable>
              </b-col>
            </b-row>
          </el-descriptions-item>
        </el-descriptions>
        <div>
          <el-button
            style="float: right; width: 200px; background: #66b1ff; border: #66b1ff; margin: 20px 0 20px 20px;"
            type="primary" @click="save()" icon="el-icon-check">{{$t('Save Pipeline')}}
          </el-button>
          <el-button style="float: right; margin: 20px 0; width: 200px; background: #fd7e14; border: #fd7e14;"
                     type="primary" icon="el-icon-plus" @click="addStage()">{{$t('Add Stage')}}
          </el-button>
        </div>
      </div>
      </el-form>
    </b-modal>
  </div>
</template>

<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@configs/apis";
import { fetcher, FetchMethod } from "@/libs/axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";

export default {
  components: {
    AdvanceTable,
    vSelect,
    ToastificationContent,
    draggable
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      ownerId: "",
      columns: [
        { key: "id", modelName: "id", label: "ID", filtertype: "input", stickyColumn: false, sortable: true },
        { key: "name", modelName: "name", label: "Name", filtertype: "input", stickyColumn: false, sortable: true },
        { key: "order_nr", modelName: "order_nr", label: "Global Sorting Order", filtertype: "input", stickyColumn: false, sortable: true},
        { key: "user_order_nr", modelName: "user_order_nr", label: "My Sorting Order", filtertype: "input", stickyColumn: false, sortable: false},
        { key: "team_head", modelName: "team_head", label: "Team Head", stickyColumn: false, sortable: true },
        { key: "team_lead", modelName: "team_lead", label: "Team Lead", stickyColumn: false, sortable: true },
      ],
      form: {
        name: "",
        team_lead: null,
        team_head: null,
        order_nr: 0,
        members: [],
        stageList: [],
      },

      isLoading: false,
      drag: false,

      pipelineTitle: "Create Pipeline",
      info: {},
      isShow: false,
      userList: [],
      roleList: [{id: 'TEAM_HEAD', name: 'Team Head'}, {id: 'TEAM_LEAD', name: 'Team Lead'}, {id: 'MEMBER', name: 'Member'}],
      approvalStatusList: [{id: 'PENDING', name: 'Pending'}, {id: 'VERIFIED', name: 'Verified'}, {id: 'DOCUMENTED', name: 'Documented'}],
      sortBy: "",
      sortDesc: true
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadUserList();
  },
  methods: {
    search(key, value){
      let map = {};
      map[key] = value;
      this.$refs.advanceTable.loadList(map);
    },
    clearData() {
      Object.keys(this.form).forEach(key => {
        if (typeof this.form[key] == 'string' || typeof this.form[key] == 'number') this.form[key] = null;
        else if (typeof this.form[key] == 'object') this.form[key] = [];
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForCreate() {
      this.isShow = true;
      this.pipelineTitle = "Create Pipeline";
      this.clearData();

      this.form.order_nr = 9999;
      this.form.stageList = [{
        name: "Pending for Approval",
        deal_probability: 0,
        valid_statuses: ['PENDING'],
        is_protected: true
      }];
    },
    openModalForEdit(item) {
      this.pipelineTitle = "Edit Pipeline";

      this.form.id = item.id;
      this.form.team_head = item.team_head;
      this.form.team_lead = item.team_lead;
      this.form.name = item.name;
      this.form.order_nr = item.order_nr;
      this.form.members = item.members;
      this.form.stageList = [];

      // Workaround v-model parent null error
      this.form.members.forEach(m => {
        if (m.user === null) m.user = {};
        if (m.manager === null) m.manager = {};
      });

      this.isLoading = true;
      this.loadStage(item.id).then(() => {
        this.isLoading = false;
        this.isShow = true;
      });
    },

    addStage: function() {
      this.form.stageList.push({
        name: "",
        deal_probability: "",
        valid_statuses: []
      });
      this.$forceUpdate();
    },

    deleteStage(index, element) {
      let idx = this.form.stageList.indexOf(element);
      if (idx > -1) {
        this.form.stageList.splice(idx, 1);
        this.$forceUpdate();
      }
    },

    removeMember: function (index) {
      this.form.members.splice(index, 1);
    },

    addMember: function () {
      this.form.members.push({
        role: 'MEMBER',
        user: {},
        manager: {}
      });
    },

    async loadUserList() {
      try {
        const response = await fetcher(
          apis.crmGetUsers,
          FetchMethod.GET,
          {pageSize: 1000000}
        );
        this.userList = response.data
      } catch (e) {
        console.info(e.message);
      }
    },

    async save() {
      if (this.pipelineTitle === "Create Pipeline") {
        try {
          const response = await fetcher(
            apis.crmAddPipeline,
            FetchMethod.POST,
            {
              name: this.form.name,
              url_title: this.form.name,
              order_nr: this.form.order_nr,
              members: this.form.members,
            }
          );

          this.saveAllStages(response.id);
          this.isLoading = false;

          this.$refs.advanceTable.loadList();
          this.isShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Create Pipeline Success",
              icon: "CheckIcon",
              variant: "success"
            }
          });
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e.message + (e.detail ? ": " + e.detail : ''),
              icon: "XIcon",
              variant: "danger"
            }
          });
        }
      } else {
        try {
          const response = await fetcher(
            apis.crmUpdatePipeline + "/" + this.form.id,
            FetchMethod.PUT,
            {
              name: this.form.name,
              url_title: this.form.name,
              order_nr: this.form.order_nr,
              members: this.form.members,
            }
          );

          this.saveAllStages(this.form.id);
          this.isLoading = false;

          this.$refs.advanceTable.loadList();
          this.isShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Pipeline Success",
              icon: "CheckIcon",
              variant: "success"
            }
          });
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e.message + (e.detail ? ": " + e.detail : ''),
              icon: "XIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    async saveAllStages(pipelineId) {
      for (let i = 0; i < this.form.stageList.length; i++) {
        let data = {
          name: this.form.stageList[i].name,
          order_nr: i,
          deal_probability: this.form.stageList[i].deal_probability,
          is_protected: this.form.stageList[i].is_protected,
          valid_statuses: this.form.stageList[i].valid_statuses
        };

        if (this.form.stageList[i].id) {
          await fetcher(apis.crmUpdatePipeline + "/" + pipelineId + "/stage/" + this.form.stageList[i].id, FetchMethod.PUT, data);
        } else {
          await fetcher(apis.crmAddPipeline + "/" + pipelineId + "/stage", FetchMethod.POST, data);
        }
      }
    },

    async loadStage(pipelineId) {
      const responseStage = await fetcher(apis.crmGetPipeline + "/" + pipelineId + "/stage", FetchMethod.GET);
      this.form.stageList = responseStage.data;
    }
  }
};
</script>

<style scoped></style>
